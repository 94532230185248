import React from "react"
import Slider from "react-slick"
import BlogFeaturedImage from "./BlogFeaturedImage"

const BlogCarousel = ({ blogPosts, title }) => {
  const sliderSettings = {
    dots: true,
    arrows: false,
    centerMode: true,
    centerPadding: "150px",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "20px",
        },
      },
    ],
  }

  return (
    <div className="blog-carousel">
      {title ? (
        <div className="blog-carousel__header">
          <h2>{title}</h2>
        </div>
      ) : null}
      <Slider {...sliderSettings}>
        {blogPosts.map((post) => {
          return (
            <div className="blog-carousel-item" key={post.id}>
              <div className="card blog-card blog-card--featured">
                <div className="blog-card__left">
                  <BlogFeaturedImage featuredImage={post.featuredImage} />
                </div>
                <div className="blog-card__right">
                  <div className="blog-card__right__heading">
                    <h3>{post.title}</h3>
                    <h4>By {post.author.node.name}</h4>
                    <div className="blog-card__right__date">{new Date(post.date).toDateString()}</div>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>
                  <div className="blog-card__right__cta">
                    {post.externalArticleLink?.linkToArticle ? (
                      <a href={post.externalArticleLink.linkToArticle} className="button button--external button--primary" aria-label={"Read more about " + title} target="_blank">
                        Read More
                      </a>
                    ) : (
                      <a href={post.link} className="button button--primary" aria-label={"Read more about " + title} target="_blank">
                        Read More
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default BlogCarousel
